!function (e, t) {
  if ("object" == typeof exports && "object" == typeof module) module.exports = t(require("react"), require("semantic-ui-react"), require("prop-types"), require("react-router-dom"));else if ("function" == typeof define && define.amd) define(["react", "semantic-ui-react", "prop-types", "react-router-dom"], t);else {
    var r = "object" == typeof exports ? t(require("react"), require("semantic-ui-react"), require("prop-types"), require("react-router-dom")) : t(e.react, e["semantic-ui-react"], e["prop-types"], e["react-router-dom"]);
    for (var n in r) ("object" == typeof exports ? exports : e)[n] = r[n];
  }
}(window, function (e, t, r, n) {
  return function (e) {
    var t = {};
    function r(n) {
      if (t[n]) return t[n].exports;
      var o = t[n] = {
        i: n,
        l: !1,
        exports: {}
      };
      return e[n].call(o.exports, o, o.exports, r), o.l = !0, o.exports;
    }
    return r.m = e, r.c = t, r.d = function (e, t, n) {
      r.o(e, t) || Object.defineProperty(e, t, {
        enumerable: !0,
        get: n
      });
    }, r.r = function (e) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    }, r.t = function (e, t) {
      if (1 & t && (e = r(e)), 8 & t) return e;
      if (4 & t && "object" == typeof e && e && e.__esModule) return e;
      var n = Object.create(null);
      if (r.r(n), Object.defineProperty(n, "default", {
        enumerable: !0,
        value: e
      }), 2 & t && "string" != typeof e) for (var o in e) r.d(n, o, function (t) {
        return e[t];
      }.bind(null, o));
      return n;
    }, r.n = function (e) {
      var t = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return r.d(t, "a", t), t;
    }, r.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, r.p = "", r(r.s = 258);
  }({
    0: function _(t, r) {
      t.exports = e;
    },
    1: function _(e, r) {
      e.exports = t;
    },
    10: function _(e, t) {
      e.exports = n;
    },
    258: function _(e, t, r) {
      "use strict";

      r.r(t);
      var n = r(0),
        o = r.n(n),
        i = (r(6), r(10)),
        l = r(1);
      var a = function a(e) {
        var t = e.logo,
          r = e.release,
          n = e.leftLinks,
          a = e.rightLinks,
          c = e.socialLinks,
          u = e.copyrightText,
          s = o.a.createElement("div", {
            className: "footer"
          }, o.a.createElement(l.Grid, {
            stackable: !0,
            relaxed: !0
          }, o.a.createElement(l.Grid.Column, {
            mobile: 8,
            computer: 4,
            verticalAlign: "top",
            textAlign: "left"
          }, o.a.createElement(l.Grid.Row, null, n.map(function (e) {
            return o.a.createElement("div", {
              key: e.label,
              className: "link"
            }, o.a.createElement(i.Link, {
              to: e.URL
            }, e.label));
          }))), o.a.createElement(l.Grid.Column, {
            mobile: 8,
            computer: 4,
            verticalAlign: "top",
            textAlign: "left"
          }, o.a.createElement(l.Grid.Row, null, a.map(function (e) {
            return o.a.createElement("div", {
              key: e.label,
              className: "link"
            }, o.a.createElement(i.Link, {
              to: e.URL
            }, e.label));
          }))), o.a.createElement(l.Grid.Column, {
            mobile: 8,
            computer: 4,
            verticalAlign: "top",
            textAlign: "left"
          }, o.a.createElement(l.Grid.Row, null, c.map(function (e) {
            return o.a.createElement("div", {
              key: e.label,
              className: "link"
            }, o.a.createElement("a", {
              href: e.URL
            }, e.label));
          }))), o.a.createElement(l.Grid.Column, {
            mobile: 8,
            computer: 4,
            verticalAlign: "bottom",
            textAlign: window.innerWidth > 992 ? "right" : "left"
          }, o.a.createElement(l.Grid.Row, null, o.a.createElement("img", {
            className: "logo",
            src: t,
            alt: ""
          })), o.a.createElement(l.Grid.Row, null, u))));
        return o.a.createElement(l.Popup, {
          position: "top center",
          basic: !0,
          on: "click",
          flowing: !0,
          hideOnScroll: !0,
          trigger: s,
          content: "Build: ".concat(r.build, " - Version: ").concat(r.version)
        });
      };
      a.defaultProps = {
        logo: "",
        release: {
          build: "missing build",
          version: "missing version"
        },
        leftLinks: [],
        rightLinks: [],
        socialLinks: [],
        copyrightText: ""
      }, t.default = a;
    },
    6: function _(e, t) {
      e.exports = r;
    }
  });
});