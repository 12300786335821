var _objectSpread = require("/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/objectSpread");
var _slicedToArray = require("/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/slicedToArray");
!function (e, t) {
  if ("object" == typeof exports && "object" == typeof module) module.exports = t(require("react"));else if ("function" == typeof define && define.amd) define(["react"], t);else {
    var n = "object" == typeof exports ? t(require("react")) : t(e.react);
    for (var i in n) ("object" == typeof exports ? exports : e)[i] = n[i];
  }
}(window, function (e) {
  return function (e) {
    var t = {};
    function n(i) {
      if (t[i]) return t[i].exports;
      var r = t[i] = {
        i: i,
        l: !1,
        exports: {}
      };
      return e[i].call(r.exports, r, r.exports, n), r.l = !0, r.exports;
    }
    return n.m = e, n.c = t, n.d = function (e, t, i) {
      n.o(e, t) || Object.defineProperty(e, t, {
        enumerable: !0,
        get: i
      });
    }, n.r = function (e) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    }, n.t = function (e, t) {
      if (1 & t && (e = n(e)), 8 & t) return e;
      if (4 & t && "object" == typeof e && e && e.__esModule) return e;
      var i = Object.create(null);
      if (n.r(i), Object.defineProperty(i, "default", {
        enumerable: !0,
        value: e
      }), 2 & t && "string" != typeof e) for (var r in e) n.d(i, r, function (t) {
        return e[t];
      }.bind(null, r));
      return i;
    }, n.n = function (e) {
      var t = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return n.d(t, "a", t), t;
    }, n.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }, n.p = "", n(n.s = 29);
  }({
    0: function _(t, n) {
      t.exports = e;
    },
    29: function _(e, t, n) {
      "use strict";

      n.r(t), n.d(t, "responsive", function () {
        return r;
      }), n.d(t, "useResponsive", function () {
        return o;
      });
      var i = n(0);
      var r = {
          onlyMobile: {
            maxWidth: 767,
            minWidth: 320
          },
          onlyTablet: {
            minWidth: 768,
            maxWidth: 991
          },
          onlyComputer: {
            minWidth: 992
          }
        },
        o = function o() {
          var _Object = Object(i.useState)({
              width: void 0,
              height: void 0
            }),
            _Object2 = _slicedToArray(_Object, 2),
            e = _Object2[0],
            t = _Object2[1],
            _Object3 = Object(i.useState)(void 0),
            _Object4 = _slicedToArray(_Object3, 2),
            n = _Object4[0],
            o = _Object4[1],
            _Object5 = Object(i.useState)(void 0),
            _Object6 = _slicedToArray(_Object5, 2),
            u = _Object6[0],
            d = _Object6[1],
            _Object7 = Object(i.useState)(void 0),
            _Object8 = _slicedToArray(_Object7, 2),
            c = _Object8[0],
            f = _Object8[1];
          return Object(i.useEffect)(function () {
            function e() {
              o(window.innerWidth < r.onlyMobile.maxWidth), d(window.innerWidth > r.onlyTablet.minWidth && window.innerWidth < r.onlyTablet.maxWidth), f(window.innerWidth > r.onlyComputer.minWidth), t({
                width: window.innerWidth,
                height: window.innerHeight
              });
            }
            return window.addEventListener("resize", e), e(), function () {
              return window.removeEventListener("resize", e);
            };
          }, []), _objectSpread({
            isMobile: n,
            isTablet: u,
            isComputer: c
          }, e);
        };
    }
  });
});