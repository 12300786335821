var _defineProperty = require("/app/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/defineProperty");
!function (e, a) {
  if ("object" == typeof exports && "object" == typeof module) module.exports = a(require("react"), require("semantic-ui-react"), require("react-intl"));else if ("function" == typeof define && define.amd) define(["react", "semantic-ui-react", "react-intl"], a);else {
    var l = "object" == typeof exports ? a(require("react"), require("semantic-ui-react"), require("react-intl")) : a(e.react, e["semantic-ui-react"], e["react-intl"]);
    for (var t in l) ("object" == typeof exports ? exports : e)[t] = l[t];
  }
}(window, function (e, a, l) {
  return function (e) {
    var a = {};
    function l(t) {
      if (a[t]) return a[t].exports;
      var s = a[t] = {
        i: t,
        l: !1,
        exports: {}
      };
      return e[t].call(s.exports, s, s.exports, l), s.l = !0, s.exports;
    }
    return l.m = e, l.c = a, l.d = function (e, a, t) {
      l.o(e, a) || Object.defineProperty(e, a, {
        enumerable: !0,
        get: t
      });
    }, l.r = function (e) {
      "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      }), Object.defineProperty(e, "__esModule", {
        value: !0
      });
    }, l.t = function (e, a) {
      if (1 & a && (e = l(e)), 8 & a) return e;
      if (4 & a && "object" == typeof e && e && e.__esModule) return e;
      var t = Object.create(null);
      if (l.r(t), Object.defineProperty(t, "default", {
        enumerable: !0,
        value: e
      }), 2 & a && "string" != typeof e) for (var s in e) l.d(t, s, function (a) {
        return e[a];
      }.bind(null, s));
      return t;
    }, l.n = function (e) {
      var a = e && e.__esModule ? function () {
        return e.default;
      } : function () {
        return e;
      };
      return l.d(a, "a", a), a;
    }, l.o = function (e, a) {
      return Object.prototype.hasOwnProperty.call(e, a);
    }, l.p = "", l(l.s = 26);
  }({
    0: function _(a, l) {
      a.exports = e;
    },
    1: function _(e, l) {
      e.exports = a;
    },
    11: function _(e, a) {
      function l() {
        return e.exports = l = Object.assign ? Object.assign.bind() : function (e) {
          for (var a = 1; a < arguments.length; a++) {
            var l = arguments[a];
            for (var t in l) Object.prototype.hasOwnProperty.call(l, t) && (e[t] = l[t]);
          }
          return e;
        }, e.exports.__esModule = !0, e.exports.default = e.exports, l.apply(this, arguments);
      }
      e.exports = l, e.exports.__esModule = !0, e.exports.default = e.exports;
    },
    2: function _(e, a) {
      e.exports = l;
    },
    26: function _(e, a, l) {
      "use strict";

      l.r(a), l.d(a, "default", function () {
        return n;
      });
      var t = l(11),
        s = l.n(t),
        o = l(0),
        i = l.n(o),
        d = l(2),
        r = l(1),
        u = l(3);
      function n(e) {
        var _Object = Object(d.useIntl)(),
          a = _Object.formatMessage;
        return i.a.createElement(r.Loader, s()({
          active: !0
        }, e, {
          style: {
            fontSize: "16px",
            fontWeight: 500,
            color: "var(--primaryColor)"
          }
        }), a(u.a.loading));
      }
    },
    3: function _(e, a, l) {
      "use strict";

      var t = l(2);
      a.a = Object(t.defineMessages)(_defineProperty({
        back: {
          id: "global.back",
          defaultMessage: [{
            type: 0,
            value: "Back"
          }]
        },
        readMore: {
          id: "global.readMore",
          defaultMessage: [{
            type: 0,
            value: "Read more"
          }]
        },
        more: {
          id: "global.more",
          defaultMessage: [{
            type: 0,
            value: "More"
          }]
        },
        send: {
          id: "global.send",
          defaultMessage: [{
            type: 0,
            value: "Send"
          }]
        },
        select: {
          id: "global.select",
          defaultMessage: [{
            type: 0,
            value: "Select"
          }]
        },
        apply: {
          id: "global.apply",
          defaultMessage: [{
            type: 0,
            value: "Apply"
          }]
        },
        cancel: {
          id: "global.cancel",
          defaultMessage: [{
            type: 0,
            value: "Cancel"
          }]
        },
        order: {
          id: "global.order",
          defaultMessage: [{
            type: 0,
            value: "Order"
          }]
        },
        download: {
          id: "global.download",
          defaultMessage: [{
            type: 0,
            value: "Download"
          }]
        },
        ok: {
          id: "global.ok",
          defaultMessage: [{
            type: 0,
            value: "OK"
          }]
        },
        yes: {
          id: "global.yes",
          defaultMessage: [{
            type: 0,
            value: "Yes"
          }]
        },
        no: {
          id: "global.no",
          defaultMessage: [{
            type: 0,
            value: "No"
          }]
        },
        pay: {
          id: "global.pay",
          defaultMessage: [{
            type: 0,
            value: "Pay"
          }]
        },
        payAmount: {
          id: "global.payAmount",
          defaultMessage: [{
            type: 0,
            value: "Pay "
          }, {
            type: 1,
            value: "amount"
          }]
        },
        service: {
          id: "global.service",
          defaultMessage: [{
            type: 0,
            value: "Service"
          }]
        },
        provider: {
          id: "global.provider",
          defaultMessage: [{
            type: 0,
            value: "Provider"
          }]
        },
        contacts: {
          id: "global.contacts",
          defaultMessage: [{
            type: 0,
            value: "Contacts"
          }]
        },
        contactUs: {
          id: "global.contactUs",
          defaultMessage: [{
            type: 0,
            value: "Contact us"
          }]
        },
        details: {
          id: "global.details",
          defaultMessage: [{
            type: 0,
            value: "Details"
          }]
        },
        duration: {
          id: "global.duration",
          defaultMessage: [{
            type: 0,
            value: "Duration"
          }]
        },
        contract: {
          id: "global.contract",
          defaultMessage: [{
            type: 0,
            value: "Contract"
          }]
        },
        notice: {
          id: "global.notice",
          defaultMessage: [{
            type: 0,
            value: "Notice"
          }]
        },
        cost: {
          id: "global.cost",
          defaultMessage: [{
            type: 0,
            value: "Cost"
          }]
        },
        total: {
          id: "global.total",
          defaultMessage: [{
            type: 0,
            value: "Total"
          }]
        },
        done: {
          id: "global.done",
          defaultMessage: [{
            type: 0,
            value: "Done"
          }]
        },
        firstName: {
          id: "global.firstName",
          defaultMessage: [{
            type: 0,
            value: "First name"
          }]
        },
        lastName: {
          id: "global.lastName",
          defaultMessage: [{
            type: 0,
            value: "Last name"
          }]
        },
        birthdate: {
          id: "global.birthdate",
          defaultMessage: [{
            type: 0,
            value: "Birthdate"
          }]
        },
        phone: {
          id: "global.phone",
          defaultMessage: [{
            type: 0,
            value: "Phone"
          }]
        },
        mobile: {
          id: "global.mobile",
          defaultMessage: [{
            type: 0,
            value: "Mobile"
          }]
        },
        email: {
          id: "global.email",
          defaultMessage: [{
            type: 0,
            value: "Email"
          }]
        },
        emailRepeat: {
          id: "global.emailRepeat",
          defaultMessage: [{
            type: 0,
            value: "Repeat e-mail"
          }]
        },
        address: {
          id: "global.address",
          defaultMessage: [{
            type: 0,
            value: "Address"
          }]
        },
        streetAddress: {
          id: "global.streetAddress",
          defaultMessage: [{
            type: 0,
            value: "Street address"
          }]
        },
        postalCode: {
          id: "global.postalCode",
          defaultMessage: [{
            type: 0,
            value: "Postal code"
          }]
        },
        province: {
          id: "global.province",
          defaultMessage: [{
            type: 0,
            value: "Province"
          }]
        },
        city: {
          id: "global.city",
          defaultMessage: [{
            type: 0,
            value: "City"
          }]
        },
        customerType: {
          id: "global.customerType",
          defaultMessage: [{
            type: 0,
            value: "Customer type"
          }]
        },
        customerNumber: {
          id: "global.customerNumber",
          defaultMessage: [{
            type: 0,
            value: "Customer number"
          }]
        },
        ssn: {
          id: "global.ssn",
          defaultMessage: [{
            type: 0,
            value: "SSN"
          }]
        },
        password: {
          id: "global.password",
          defaultMessage: [{
            type: 0,
            value: "Password"
          }]
        },
        confirmPassword: {
          id: "global.confirmPassword",
          defaultMessage: [{
            type: 0,
            value: "Confirm Password"
          }]
        },
        newPassword: {
          id: "global.newPassword",
          defaultMessage: [{
            type: 0,
            value: "New Password"
          }]
        },
        companyName: {
          id: "global.form.company",
          defaultMessage: [{
            type: 0,
            value: "Company name"
          }]
        },
        businessType: {
          id: "global.form.businessType",
          defaultMessage: [{
            type: 0,
            value: "Business type"
          }]
        },
        monthlyPriceMin: {
          id: "global.monthlyPriceMin",
          defaultMessage: [{
            type: 0,
            value: "per month"
          }]
        },
        annualPrice: {
          id: "global.annualPrice",
          defaultMessage: [{
            type: 0,
            value: "Annual cost"
          }]
        },
        serviceStartupPrice: {
          id: "global.serviceStartupPrice",
          defaultMessage: [{
            type: 6,
            value: "start_price",
            options: {
              "=0": {
                value: [{
                  type: 1,
                  value: "cost"
                }]
              },
              other: {
                value: [{
                  type: 1,
                  value: "cost"
                }, {
                  type: 0,
                  value: " Startup cost"
                }]
              }
            },
            offset: 0,
            pluralType: "cardinal"
          }]
        },
        serviceHomedropPrice: {
          id: "global.serviceHomedropPrice",
          defaultMessage: [{
            type: 8,
            value: "p",
            children: [{
              type: 0,
              value: "Homedrop "
            }, {
              type: 1,
              value: "homedrop_price"
            }]
          }]
        },
        comingSoon: {
          id: "global.coming_soon",
          defaultMessage: [{
            type: 0,
            value: "Coming soon"
          }]
        },
        emailNotValid: {
          id: "global.emailNotValid",
          defaultMessage: [{
            type: 0,
            value: "Email not valid"
          }]
        },
        phoneNotValid: {
          id: "global.phoneNotValid",
          defaultMessage: [{
            type: 0,
            value: "Phone not valid"
          }]
        },
        message: {
          id: "global.message",
          defaultMessage: [{
            type: 0,
            value: "Message"
          }]
        },
        less: {
          id: "global.less",
          defaultMessage: [{
            type: 0,
            value: "Less"
          }]
        },
        confirm: {
          id: "global.confirm",
          defaultMessage: [{
            type: 0,
            value: "Confirm"
          }]
        },
        next: {
          id: "global.next",
          defaultMessage: [{
            type: 0,
            value: "Next"
          }]
        },
        login: {
          id: "global.login",
          defaultMessage: [{
            type: 0,
            value: "login"
          }]
        },
        required: {
          id: "global.required",
          defaultMessage: [{
            type: 0,
            value: "This is required"
          }]
        },
        loading: {
          id: "global.loading",
          defaultMessage: [{
            type: 0,
            value: "Loading"
          }]
        },
        startupPrice: {
          id: "global.startup_price",
          defaultMessage: [{
            type: 0,
            value: "Startup cost"
          }]
        },
        monthly_price: {
          id: "global.monthly_price",
          defaultMessage: [{
            type: 0,
            value: "Monthly cost"
          }]
        },
        select_another_postcode: {
          id: "global.back.select_another_postcode",
          defaultMessage: [{
            type: 0,
            value: "Select another postcode"
          }]
        },
        enter_manual_address: {
          id: "global.enter_manual_address",
          defaultMessage: [{
            type: 0,
            value: "Enter address manually"
          }]
        },
        organisation_name: {
          id: "global.organisation_name",
          defaultMessage: [{
            type: 0,
            value: "Organisation name"
          }]
        },
        building: {
          id: "global.building",
          defaultMessage: [{
            type: 0,
            value: "Building"
          }]
        },
        flat: {
          id: "global.flat",
          defaultMessage: [{
            type: 0,
            value: "Flat"
          }]
        },
        street: {
          id: "global.street",
          defaultMessage: [{
            type: 0,
            value: "Street"
          }]
        },
        county: {
          id: "global.county",
          defaultMessage: [{
            type: 0,
            value: "County"
          }]
        },
        post_code: {
          id: "global.post_code",
          defaultMessage: [{
            type: 0,
            value: "Post code"
          }]
        },
        form_tax_id: {
          id: "global.form.form_tax_id",
          defaultMessage: [{
            type: 0,
            value: "Tax ID"
          }]
        },
        form_business: {
          id: "global.form.business",
          defaultMessage: [{
            type: 0,
            value: "Business"
          }]
        },
        form_residential: {
          id: "global.form.residential",
          defaultMessage: [{
            type: 0,
            value: "Residential"
          }]
        },
        form_submit: {
          id: "global.form.submit",
          defaultMessage: [{
            type: 0,
            value: "Submit your interest"
          }]
        },
        form_submit_pending: {
          id: "global.submit.pending",
          defaultMessage: [{
            type: 0,
            value: "Loading"
          }]
        },
        form_submit_positive: {
          id: "global.submit.positive",
          defaultMessage: [{
            type: 0,
            value: "Sended"
          }]
        },
        form_submit_negative: {
          id: "global.submit.negative",
          defaultMessage: [{
            type: 0,
            value: "Retry"
          }]
        },
        form_your_place: {
          id: "global.form.yourPlace",
          defaultMessage: [{
            type: 0,
            value: "Your Place"
          }]
        },
        form_building: {
          id: "global.form.building",
          defaultMessage: [{
            type: 0,
            value: "Building"
          }]
        },
        form_apartment_number: {
          id: "global.form.apartmentNumber",
          defaultMessage: [{
            type: 0,
            value: "Apartment number"
          }]
        },
        form_mailbox: {
          id: "global.form.mailbox",
          defaultMessage: [{
            type: 0,
            value: "Mailbox"
          }]
        },
        form_billing_checkbox: {
          id: "global.form.billingCheckbox",
          defaultMessage: [{
            type: 0,
            value: "Use different address for billing"
          }]
        },
        form_billing_name: {
          id: "global.form.billingName",
          defaultMessage: [{
            type: 0,
            value: "Billing name"
          }]
        },
        form_billing_address: {
          id: "global.form.billingAddress",
          defaultMessage: [{
            type: 0,
            value: "Billing address"
          }]
        },
        form_billing_postalcode: {
          id: "global.form.billingPostalCode",
          defaultMessage: [{
            type: 0,
            value: "Billing Postal Code"
          }]
        },
        form_billing_city: {
          id: "global.form.billingCity",
          defaultMessage: [{
            type: 0,
            value: "Billing City"
          }]
        },
        form_your_details: {
          id: "global.form.yourDetails",
          defaultMessage: [{
            type: 0,
            value: "Your details"
          }]
        },
        form_username: {
          id: "global.form.username",
          defaultMessage: [{
            type: 0,
            value: "Email"
          }]
        },
        form_recovery_code: {
          id: "global.form.recoveryCode",
          defaultMessage: [{
            type: 0,
            value: "Recovery Code"
          }]
        },
        form_confirm: {
          id: "global.form.confirm",
          defaultMessage: [{
            type: 0,
            value: "Confirm"
          }]
        },
        form_saved: {
          id: "global.form.saved",
          defaultMessage: [{
            type: 0,
            value: "Saved"
          }]
        },
        form_email_mobile: {
          id: "global.form.email_mobile",
          defaultMessage: [{
            type: 0,
            value: "Email or mobile phone"
          }]
        },
        form_placeholder_customer_type: {
          id: "global.form.placeholder.customerType",
          defaultMessage: [{
            type: 0,
            value: "Choose a customer type"
          }]
        },
        form_campaign_code: {
          id: "global.form.campaign_code",
          defaultMessage: [{
            type: 0,
            value: "Campaign Code"
          }]
        },
        form_terms_and_conditions: {
          id: "global.form.terms_and_conditions",
          defaultMessage: [{
            type: 0,
            value: "I agree to"
          }]
        },
        edit_address: {
          id: "global.editAddress",
          defaultMessage: [{
            type: 0,
            value: "Edit address"
          }]
        },
        reactivate: {
          id: "global.reactivate",
          defaultMessage: [{
            type: 0,
            value: "Reactivate"
          }]
        },
        suspend: {
          id: "global.suspend",
          defaultMessage: [{
            type: 0,
            value: "Suspend"
          }]
        },
        description: {
          id: "global.description",
          defaultMessage: [{
            type: 0,
            value: "Description"
          }]
        },
        modal_header: {
          id: "global.modal.header",
          defaultMessage: [{
            type: 0,
            value: "Your interest has been accepted!"
          }]
        },
        modal_content: {
          id: "global.modal.content",
          defaultMessage: [{
            type: 0,
            value: "You will receive a confirmation email shortly. You need to confirm your interest by clicking on the received link."
          }]
        },
        terms_and_conditions: {
          id: "global.terms_and_conditions",
          defaultMessage: [{
            type: 0,
            value: "Terms and Conditions"
          }]
        },
        privacy_policy: {
          id: "global.privacy_policy",
          defaultMessage: [{
            type: 0,
            value: "Privacy Policy"
          }]
        },
        search_service: {
          id: "global.search_service",
          defaultMessage: [{
            type: 0,
            value: "Search service"
          }]
        },
        sort_by: {
          id: "global.sort_by",
          defaultMessage: [{
            type: 0,
            value: "Sort by"
          }]
        },
        services: {
          id: "global.services",
          defaultMessage: [{
            type: 0,
            value: "Services"
          }]
        },
        type: {
          id: "global.type",
          defaultMessage: [{
            type: 0,
            value: "Type"
          }]
        },
        speed: {
          id: "global.speed",
          defaultMessage: [{
            type: 0,
            value: "Speed"
          }]
        },
        price: {
          id: "global.price",
          defaultMessage: [{
            type: 0,
            value: "Price"
          }]
        },
        provider_info: {
          id: "global.provider_info",
          defaultMessage: [{
            type: 0,
            value: "Provider Informations"
          }]
        },
        password_requirements: {
          id: "global.password_requirements",
          defaultMessage: [{
            type: 0,
            value: "Password requirements"
          }]
        },
        password_requirement1: {
          id: "global.requirement1",
          defaultMessage: [{
            type: 0,
            value: "It must be at least 8 characters long"
          }]
        },
        password_requirement2: {
          id: "global.requirement2",
          defaultMessage: [{
            type: 0,
            value: "It must include of one or more numerical digits"
          }]
        },
        password_requirement3: {
          id: "global.requirement3",
          defaultMessage: [{
            type: 0,
            value: "It must contain at least one upper-case and one lower-case letter"
          }]
        },
        password_requirement4: {
          id: "global.requirement4",
          defaultMessage: [{
            type: 0,
            value: "It must include at least one special character, such as !@$/:^%&*()_+={}[,.><?#]-"
          }]
        },
        requirements_not_fulfilled: {
          id: "global.requirements_not_fulfilled",
          defaultMessage: [{
            type: 0,
            value: "Password requirements not matched"
          }]
        },
        passwords_not_matching: {
          id: "global.passwords_not_matching",
          defaultMessage: [{
            type: 0,
            value: "Passwords do not match"
          }]
        }
      }, "password", {
        id: "global.password",
        defaultMessage: [{
          type: 0,
          value: "Password"
        }]
      }));
    }
  });
});