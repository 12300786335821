import { RECEIVE_INIT_DATA } from '../actions/init.actions';

export default function init(state = {}, { type, data }) {
  switch (type) {
    case RECEIVE_INIT_DATA:
      return data;
    default:
      return state;
  }
}
