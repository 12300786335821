import { defineMessages } from 'react-intl';

export default defineMessages({
  learn_more: {
    id: 'global.learn_more',
    defaultMessage: 'Learn more',
    description: 'global, learn more',
  },
  navigation_get_connected: {
    id: 'navigation.get_connected',
    defaultMessage: 'Check my availability',
    description: 'Navigation item: get connected',
  },
  yes: {
    id: 'global.yes',
    defaultMessage: 'Yes',
    description: 'global, Yes',
  },
  no: {
    id: 'global.no',
    defaultMessage: 'No',
    description: 'global, No',
  },
  next: {
    id: 'global.next',
    defaultMessage: 'Next',
    description: 'Label, next',
  },
  back: {
    id: 'global.back',
    defaultMessage: 'Back',
    description: 'Label, back',
  },
  login: {
    id: 'global.login',
    defaultMessage: 'login',
    description: 'Label, login',
  },
  loading: {
    id: 'global.loading',
    defaultMessage: 'Loading',
    description: 'Label, loading',
  },
});
