import { LOAD_LANG } from '../actions/lang.actions';

export default function lang(state = {}, { type, data }) {
  switch (type) {
    case LOAD_LANG:
      return data;
    default:
      return state;
  }
}
