export const REQUEST_SERVICE = 'REQUEST_SERVICE';
export const RECEIVE_SERVICE = 'RECEIVE_SERVICE';
export const RESET_SERVICE = 'RESET_SERVICE';

export const requestService = service => ({
  type: REQUEST_SERVICE,
  service,
});

export const receiveService = service => ({
  type: RECEIVE_SERVICE,
  service,
});

export const resetService = () => ({
  type: RESET_SERVICE,
});
