import { RECEIVE_ORDER_GET } from '../actions/order.actions';

export default function order(state = {}, { type, data }) {
  switch (type) {
    case RECEIVE_ORDER_GET:
      return data;
    default:
      return state;
  }
}
