import { TOGGLE_CUSTOMER_TYPE } from '../actions/customerType.actions';

const InitCustomerType = localStorage.getItem('customerType');

export default function customerType(state = InitCustomerType || 'Residential', { type, data }) {
  switch (type) {
    case TOGGLE_CUSTOMER_TYPE:
      return data;
    default:
      return state;
  }
}
