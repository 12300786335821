import React, { useState, useEffect } from 'react';

import moment from 'moment';
// import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
// Link,
import { Accordion, Button, Checkbox, Dimmer, Divider, Grid, GridColumn } from 'semantic-ui-react';
// Divider

import cookieIcon from '../../assets/cookie.svg';
import './style.css';

const injectGAScript = (googleTagManagerContainerId) => {
  // STORE the consent only if is not previously given
  if (!localStorage.getItem('consent')) {
    // TODO for GDPR compliance consent should be stored into DB
    // with an incremental logic 'cause it should be denied and given
    // multiple times from the same user/anonymous and legals has to be able to know exactly when
    localStorage.setItem('consent', 'Given on ' + moment(Date.now()).format('LLL'));
  }

  // Create the iFrame only if is not just created
  if (document.getElementById('ga-iframe')) {
    return;
  }

  // CREATE th GTM HEAD script
  const GTMHeadScript = document.createElement('script');

  /* eslint-disable quotes */
  GTMHeadScript.innerText =
    `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':` +
    `new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],` +
    `j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=` +
    `'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);` +
    `})(window,document,'script','dataLayer','${googleTagManagerContainerId}');`;

  // CREATE the GTM BODY script elements
  const GTMBodyScript = document.createElement('noscript');
  const GTMiFrame = document.createElement('iframe');
  GTMiFrame.setAttribute('id', 'ga-iframe');

  const iFrameWidth = document.createAttribute('width');
  const iFrameHeight = document.createAttribute('height');
  iFrameWidth.value = '0';
  iFrameHeight.value = '0';

  // APPLY attributes to the <iframe>
  GTMiFrame.src = `https://www.googletagmanager.com/ns.html?id=${googleTagManagerContainerId}`;
  GTMiFrame.setAttributeNode(iFrameWidth);
  GTMiFrame.setAttributeNode(iFrameHeight);
  GTMiFrame.style.display = 'none';
  GTMiFrame.style.visibility = 'hidden';

  // APPEND the <iframe> into the <noscript> element
  GTMBodyScript.appendChild(GTMiFrame);

  // APPEND scripts to the DOM
  document.head.appendChild(GTMHeadScript);
  document.body.appendChild(GTMBodyScript);
};

function CookieBanner(props) {
  const { baseURL, cookiePolicyPath = 'cookie-policy', googleTagManagerContainerId } = props;
  const location = useLocation();
  //   const { formatMessage } = useIntl();

  // Mandatory cookies
  const consent = localStorage.getItem('consent');

  // Optional cookies
  const funcitonal = localStorage.getItem('functionalCookie');
  const analytic = localStorage.getItem('analyticCookie');
  const leistung = localStorage.getItem('leistungCookie');
  const werbung = localStorage.getItem('werbungCookie');

  const cleanedBaseUrl = baseURL.replace(/\//, '');
  const cleanedLocation = location.pathname.replace(/\//, '');

  // SET the local states
  const [gtmCookie, setGtmCookie] = useState();

  const [expandCustomization, setExpandCustomization] = useState(false);
  const [showCookieIcon, setShowCookieIcon] = useState(false);

  // Checkboxes
  const [necessaryChecked, setNecessaryChecked] = useState(true);
  const [functionalChecked, setFunctionalChecked] = useState(false);
  const [analyticChecked, setAnalyticChecked] = useState(false);
  const [leistungChecked, setLeistungChecked] = useState(false);
  const [werbungChecked, setWerbungChecked] = useState(false);

  useEffect(() => {
    // Add Google Tag Manager scripts if consent was previously given
    if (consent) {
      injectScript();
      setShowCookieIcon(true);
    } else {
      setGtmCookie(false);
    }

    // Set true optional cookies if there are setted in localSotrage
    funcitonal && setFunctionalChecked(true);
    analytic && setAnalyticChecked(true);
    leistung && setLeistungChecked(true);
    werbung && setWerbungChecked(true);
  }, []);

  const injectScript = () => {
    injectGAScript(googleTagManagerContainerId);

    setGtmCookie(true);
    setShowCookieIcon(true);

    setNecessaryChecked(true);
    setExpandCustomization(false);
  };

  const handleExpandCustomization = () => {
    setExpandCustomization(!expandCustomization);
  };

  const handleNecessaryCheckbox = (event) => {
    event.stopPropagation();
  };

  const handleFunctionalCheckbox = (event) => {
    event.stopPropagation();

    if (functionalChecked) {
      setFunctionalChecked(false);
      localStorage.removeItem('functionalCookie');
    } else {
      setFunctionalChecked(true);
      localStorage.setItem('functionalCookie', true);
    }
  };

  const handleAnalytikCheckbox = (event) => {
    event.stopPropagation();

    if (analyticChecked) {
      setAnalyticChecked(false);
      localStorage.removeItem('analyticCookie');
    } else {
      setAnalyticChecked(true);
      localStorage.setItem('analyticCookie', true);
    }
  };

  const handleLeistungCheckbox = (event) => {
    event.stopPropagation();
    if (leistungChecked) {
      setLeistungChecked(false);
      localStorage.removeItem('leistungCookie');
    } else {
      setLeistungChecked(true);
      localStorage.setItem('leistungCookie', true);
    }
  };

  const handleWerbungCheckbox = (event) => {
    event.stopPropagation();
    if (werbungChecked) {
      setWerbungChecked(false);
      localStorage.removeItem('werbungCookie');
    } else {
      setWerbungChecked(true);
      localStorage.setItem('werbungCookie', true);
    }
  };

  const handleCookieIcon = () => {
    setGtmCookie(false);
    setShowCookieIcon(!setShowCookieIcon);
  };

  const closeBanner = () => {
    // Remove cookie consent if banner is just closed
    consent && localStorage.removeItem('consent');

    setExpandCustomization(false);
    setGtmCookie(true);
    setShowCookieIcon(true);

    manageAllOptionalCookies(false);

    // Select all the cookies that are injected in the website
    const cookies = document.cookie.split(';');
    cookies.length > 0 && deleteAllCookies(cookies);

    // Remove optional cookies if we reject cookies
    localStorage.removeItem('functionalCookie');
    localStorage.removeItem('analyticCookie');
    localStorage.removeItem('leistungCookie');
    localStorage.removeItem('werbungCookie');

    // Remove the created iFrame for GA cookies
    if (document.getElementById('ga-iframe')) {
      document.getElementById('ga-iframe').remove();
    }
  };

  const handleAcceptAll = () => {
    injectScript();

    manageAllOptionalCookies(true);

    // Set checked all optional cookies if we accept all cookies
    localStorage.setItem('functionalCookie', true);
    localStorage.setItem('analyticCookie', true);
    localStorage.setItem('leistungCookie', true);
    localStorage.setItem('werbungCookie', true);

    // reload the page after accepting the cookies to be sure
    // that will be injected
    window.location.reload();
  };

  const handleSavePreference = () => {
    injectScript();

    // reload the page after accepting the cookies to be sure
    // that will be injected
    window.location.reload();
  };

  const manageAllOptionalCookies = (state) => {
    setFunctionalChecked(state);
    setAnalyticChecked(state);
    setLeistungChecked(state);
    setWerbungChecked(state);
  };

  function deleteAllCookies(cookies) {
    const domain = window.location.hostname;
    cookies.forEach((cookie) => {
      document.cookie =
        cookie + `=; Path=/; Domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    });
  }

  // HIDE the cookie banner if:
  if (
    // 1. the consent has been given
    (consent && !setShowCookieIcon) ||
    // 2. or users are on the cookie-policy page
    cleanedLocation === `${cleanedBaseUrl}${cookiePolicyPath}` ||
    // 3. or users are on the legal pages
    cleanedLocation.includes(`${cleanedBaseUrl}legal`)
  ) {
    return null;
  }

  // Define customization Accordions
  const panels = [
    {
      key: 0,
      title: {
        content: (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <h4>Notwendig Immer aktiv</h4>
            <Checkbox
              toggle
              readOnly
              disabled
              id={'necessaryToggle'}
              checked={necessaryChecked}
              onClick={handleNecessaryCheckbox}
            />
          </div>
        ),
        icon: 'angle right',
      },
      content: {
        content: (
          <p>
            Notwendige Cookies sind erforderlich, um die grundlegenden Funktionen dieser Website zu
            ermöglichen, wie beispielsweise das sichere Einloggen oder das Anpassen Ihrer
            Einwilligungspräferenzen. Diese Cookies speichern keine persönlich identifizierbaren
            Daten.
          </p>
        ),
      },
    },
    {
      key: 1,
      title: {
        content: (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <h4>Funktional</h4>
            <Checkbox
              toggle
              id={'functionalToggle'}
              checked={functionalChecked}
              onClick={handleFunctionalCheckbox}
            />
          </div>
        ),
        icon: 'angle right',
      },
      content: {
        content: (
          <p>
            Funktionale Cookies helfen, bestimmte Funktionen wie das Teilen des Inhalts der Website
            auf sozialen Medienplattformen, das Sammeln von Feedback und andere Funktionen Dritter
            zu erfüllen.
          </p>
        ),
      },
    },
    {
      key: 2,
      title: {
        content: (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <h4>Analytik</h4>
            <Checkbox
              toggle
              id={'analytikToggle'}
              checked={analyticChecked}
              onClick={handleAnalytikCheckbox}
            />
          </div>
        ),
        icon: 'angle right',
      },
      content: {
        content: (
          <p>
            Analytische Cookies werden verwendet, um zu verstehen, wie Besucher mit der Website
            interagieren. Diese Cookies helfen, Informationen über Metriken wie die Anzahl der
            Besucher, die Absprungrate, die Verkehrsquelle usw. zu liefern.
          </p>
        ),
      },
    },
    {
      key: 3,
      title: {
        content: (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <h4>Leistung</h4>
            <Checkbox
              toggle
              id={'leistungToggle'}
              checked={leistungChecked}
              onClick={handleLeistungCheckbox}
            />
          </div>
        ),
        icon: 'angle right',
      },
      content: {
        content: (
          <p>
            Leistungs-Cookies werden verwendet, um zu verstehen und zu analysieren, welche
            Schlüsselleistungsindizes der Website helfen, eine bessere Benutzererfahrung für die
            Besucher zu liefern.
          </p>
        ),
      },
    },
    {
      key: 4,
      title: {
        content: (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            <h4>Werbung</h4>
            <Checkbox
              toggle
              id={'werbungToggle'}
              checked={werbungChecked}
              onClick={handleWerbungCheckbox}
            />
          </div>
        ),
        icon: 'angle right',
      },
      content: {
        content: (
          <p>
            Werbungs-Cookies werden verwendet, um den Besuchern angepasste Werbeanzeigen basierend
            auf den zuvor besuchten Seiten zur Verfügung zu stellen und die Wirksamkeit der
            Werbekampagnen zu analysieren.
          </p>
        ),
      },
    },
  ];

  return (
    <>
      <Dimmer className="cookie-content" page active={!gtmCookie}>
        <h3>Cookies bei LilaConnect</h3>

        {/* Short CookieBanner */}
        {!expandCustomization && (
          <div>
            <p>Wir schätzen Ihre Privatsphäre</p>
            <p>
              Wir verwenden Cookies, um Ihr Browsing-Erlebnis zu verbessern, personalisierte Werbung
              oder Inhalte bereitzustellen und unseren Verkehr zu analysieren. Indem Sie auf "Alle
              akzeptieren" klicken, stimmen Sie unserer Verwendung von Cookies zu.
            </p>
          </div>
        )}

        {/* {Expanded cookieBanner with options to select} */}
        {expandCustomization && (
          <div>
            <p>
              Wir verwenden Cookies, um Ihnen eine effiziente Navigation zu ermöglichen und
              bestimmte Funktionen auszuführen. Detaillierte Informationen zu allen Cookies finden
              Sie unter den einzelnen Zustimmungskategorien unten.
            </p>
            <p>
              Die als "Notwendig" kategorisierten Cookies werden in Ihrem Browser gespeichert, da
              sie für die Grundfunktionalitäten der Website unerlässlich sind.
            </p>
            <p>
              Wir verwenden auch Cookies von Drittanbietern, die uns helfen zu analysieren, wie Sie
              diese Website nutzen, Ihre Präferenzen speichern und Inhalte sowie Werbeanzeigen
              bereitstellen, die für Sie relevant sind. Diese Cookies werden nur mit Ihrer
              vorherigen Zustimmung in Ihrem Browser gespeichert.
            </p>
            <p>
              Sie können wählen, ob Sie einige oder alle diese Cookies aktivieren oder deaktivieren
              möchten, aber das Deaktivieren einiger von ihnen kann Ihre Browsing-Erfahrung
              beeinträchtigen.
            </p>
            <Divider className="divider-cookie" section />
            <Accordion defaultActiveIndex={[0]} panels={panels} exclusive={false} fluid />
            <Divider className="divider-cookie" section />
          </div>
        )}

        <Grid>
          <GridColumn className="cookie-banner-buttons">
            <Button className="gradient" onClick={handleAcceptAll}>
              Alle akzeptieren
            </Button>
            <Button className="btn-oultine" onClick={closeBanner}>
              Alle ablehnen
            </Button>

            {expandCustomization ? (
              <Button className="btn-oultine" onClick={handleSavePreference}>
                Meine Präferenzen speichern
              </Button>
            ) : (
              <Button className="btn-oultine" onClick={handleExpandCustomization}>
                Anpassen
              </Button>
            )}
          </GridColumn>
        </Grid>
      </Dimmer>
      {showCookieIcon && (
        <div className="cookie-icon" onClick={handleCookieIcon}>
          <img src={cookieIcon} alt="cookie icon" />
        </div>
      )}
    </>
  );
}

export { CookieBanner, injectGAScript };
