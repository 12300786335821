import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { componentLoader } from 'portal-components/utils';
import LoadingSpinner from 'portal-components/LoadingSpinner';

import faqContent from '../content/faqs.json';
import impressumContent from '../content/policies/impressum.json';
import cookiePolicyContent from '../content/policies/cookie-policy.json';
import dataProtectionContent from '../content/policies/data-protection.json';
import termsAndConditionsContent from '../content/policies/terms-and-conditions.json';
import cancellationPolicyContent from '../content/policies/cancellation-policy.json';

import HomePage from '../pages/HomePage';

const FaqPage = lazy(() => componentLoader(() => import('portal-components/FaqPage'), 3));
const NewsPage = lazy(() => componentLoader(() => import('portal-components/NewsPage'), 3));
const ProfilePage = lazy(() => componentLoader(() => import('portal-components/ProfilePage'), 3));
const NewsPostPage = lazy(() => componentLoader(() => import('portal-components/NewsPostPage'), 3));
const MarkdownPage = lazy(() => componentLoader(() => import('portal-components/MarkdownPage'), 3));
const NotFoundPage = lazy(() => componentLoader(() => import('portal-components/NotFoundPage'), 3));

const ContactUsPage = lazy(() =>
  componentLoader(() => import('portal-components/ContactUsPage'), 3),
);

const UnauthorizedPage = lazy(() =>
  componentLoader(() => import('portal-components/UnauthorizedPage'), 3),
);
const PasswordResetPage = lazy(() =>
  componentLoader(() => import('portal-components/PasswordResetPage'), 3),
);
const CustomerServicesPage = lazy(() =>
  componentLoader(() => import('portal-components/CustomerServicesPage'), 3),
);
const RequestPasswordResetPage = lazy(() =>
  componentLoader(() => import('portal-components/RequestPasswordResetPage'), 3),
);
const AccountVerificationPage = lazy(() =>
  componentLoader(() => import('portal-components/AccountVerificationPage'), 3),
);
const AccountVerificationPageNew = lazy(() =>
  componentLoader(() => import('portal-components/AccountVerificationPageNew'), 3),
);

const BusinessPage = lazy(() => componentLoader(() => import('../pages/BusinessPage'), 3));
const FullFibrePage = lazy(() => componentLoader(() => import('../pages/FullFibrePage'), 3));
const ResidentialPage = lazy(() => componentLoader(() => import('../pages/ResidentialPage'), 3));
const AboutUsPage = lazy(() => componentLoader(() => import('../pages/AboutUsPage'), 3));
const CoveragePage = lazy(() => componentLoader(() => import('../pages/CoveragePage'), 3));
const OffersPage = lazy(() => componentLoader(() => import('../pages/OffersPage'), 3));
const ServiceCatalogPage = lazy(() =>
  componentLoader(() => import('../pages/ServiceCatalogPage'), 3),
);
const PersonalAreaPage = lazy(() => componentLoader(() => import('../pages/PersonalAreaPage'), 3));
const LoginPage = lazy(() => componentLoader(() => import('../pages/LoginPage'), 3));
const SignupPage = lazy(() => componentLoader(() => import('../pages/SignupPage'), 3));
const MyPaymentsPage = lazy(() => componentLoader(() => import('../pages/MyPaymentsPage'), 3));
const SpeedTestPage = lazy(() => componentLoader(() => import('../pages/SpeedTestPage'), 3));
const AppointmentPage = lazy(() => componentLoader(() => import('../pages/AppointmentPage'), 3));
const RescheduleAppointmentPage = lazy(() =>
  componentLoader(() => import('../pages/AppointmentPage/Reschedule'), 3),
);
const ScheduleSurveyAppointmentPage = lazy(() =>
  componentLoader(() => import('../pages/ScheduleSurveyAppointmentPage'), 3),
);
const PropertyOwnerDataPage = lazy(() =>
  componentLoader(() => import('../pages/PropertyOwnerDataPage'), 3),
);
const ContractCancellationPage = lazy(() =>
  componentLoader(() => import('../pages/ContractCancellationPage'), 3),
);

const getRoutes = (initialRouteProps) => {
  let { init, ...routeProps } = initialRouteProps;

  const taxID = routeProps.config && routeProps.config.tax_id;
  const apiKey = init.api_key;
  const username = init.username;
  const autologin = init.role === 'autologin';

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route path={'/'} element={<HomePage {...routeProps} />} />
        <Route path={'/residential'} element={<ResidentialPage {...routeProps} />} />
        <Route
          path={'/contact-us'}
          element={<ContactUsPage {...{ ...routeProps, showOfficeLocationSection: false }} />}
        />
        <Route path={'/faq'} element={<FaqPage {...routeProps} contentData={faqContent} />} />
        <Route path={'/services'} element={<ServiceCatalogPage {...routeProps} />} />
        <Route path={'/coverage'} element={<CoveragePage {...routeProps} />} />
        <Route path={'/speed-test'} element={<SpeedTestPage {...routeProps} />} />
        <Route
          path={'/my-services'}
          element={
            <CustomerServicesPage
              {...routeProps}
              apiKey={apiKey}
              username={username}
              autologin={autologin}
            />
          }
        />
        <Route path={'/my-places'} element={<PersonalAreaPage {...routeProps} />} />
        <Route path={'/payments'} element={<MyPaymentsPage {...routeProps} />} />
        <Route path={'/login'} element={<LoginPage {...routeProps} />} />
        <Route path={'/signup'} element={<SignupPage {...routeProps} />} />
        <Route
          path={'/profile'}
          element={
            <ProfilePage {...routeProps} apiKey={apiKey} autologin={autologin} taxID={taxID} />
          }
        />
        <Route
          path={'/terms-and-conditions'}
          element={<MarkdownPage {...routeProps} contentData={termsAndConditionsContent} />}
        />
        <Route
          path={'/cookie-policy'}
          element={<MarkdownPage {...routeProps} contentData={cookiePolicyContent} />}
        />
        <Route
          path={'/request-password-reset'}
          element={<RequestPasswordResetPage {...routeProps} />}
        />
        <Route path={'/customer_password'} element={<PasswordResetPage {...routeProps} />} />
        <Route
          path={'/email_verification'}
          element={
            <AccountVerificationPage
              {...routeProps}
              processDefinitionKey="AccountVerificationProcess"
            />
          }
        />
        <Route
          path={'/account_verification'}
          element={<AccountVerificationPageNew {...routeProps} language="German" />}
        />
        <Route path={'/about-us'} element={<AboutUsPage {...routeProps} />} />
        <Route path={'/appointment'} element={<AppointmentPage {...routeProps} />} />
        <Route
          path={'/reschedule-appointment'}
          element={<RescheduleAppointmentPage {...routeProps} />}
        />
        <Route path={'/business'} element={<BusinessPage {...routeProps} />} />
        <Route path={'/offers'} element={<OffersPage {...routeProps} />} />
        <Route path={'/full-fibre'} element={<FullFibrePage {...routeProps} />} />
        <Route
          path={'/impressum'}
          element={<MarkdownPage {...routeProps} contentData={impressumContent} />}
        />
        <Route
          path={'/data-protection'}
          element={<MarkdownPage {...routeProps} contentData={dataProtectionContent} />}
        />
        <Route
          path={'/schedule-survey-appointment'}
          element={<ScheduleSurveyAppointmentPage {...routeProps} />}
        />
        <Route path={'/property-owner-data'} element={<PropertyOwnerDataPage {...routeProps} />} />
        <Route
          path={'/cancellation-policy'}
          element={<MarkdownPage {...routeProps} contentData={cancellationPolicyContent} />}
        />
        <Route path={'/cancel-contract'} element={<ContractCancellationPage {...routeProps} />} />

        <Route path={'/news'} element={<NewsPage {...routeProps} />} />
        <Route path={'/news-article'} element={<NewsPostPage {...routeProps} />} />

        <Route path={'/unauthorized'} element={<UnauthorizedPage {...routeProps} />} />
        <Route path="*" element={<NotFoundPage {...routeProps} />} />
      </Routes>
    </Suspense>
  );
};

export default getRoutes;
