import { RECEIVE_OBJECT } from '../actions/object.actions';

export default function object(state = {}, { type, data }) {
  switch (type) {
    case RECEIVE_OBJECT:
      return data;
    default:
      return state;
  }
};
