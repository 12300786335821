import { USER_LANG } from '../actions/userLang.actions';

export default function userLang(state = {}, { type, data }) {
  switch (type) {
    case USER_LANG:
      return data;
    default:
      return state;
  }
}
