export const REQUEST_LOGIN_DATA = 'REQUEST_LOGIN_DATA';
export const RECEIVE_LOGIN_DATA = 'RECEIVE_LOGIN_DATA';

export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
export const RECEIVE_LOGOUT = 'RECEIVE_LOGOUT';

// ACTION CREATORS
export const requestLoginData = data => ({ type: REQUEST_LOGIN_DATA, data });
export const receiveLoginData = data => ({ type: RECEIVE_LOGIN_DATA, data });

export const requestLogout = () => ({ type: REQUEST_LOGOUT });
export const receiveLogout = data => ({ type: RECEIVE_LOGOUT, data });
