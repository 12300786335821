import React from 'react';

import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { Button } from 'semantic-ui-react';
import { useResponsive } from 'portal-components/useResponsive';

import messages from '../../messages';

import contentData from '../../content/pages/home.json';

export default function HomePage(props) {
  const { slides } = contentData;
  const { config, button } = props;

  const { formatMessage } = useIntl();
  // GET window size
  const { width } = useResponsive();

  return (
    <div className="home">
      {slides.map((slide, index) => {
        // Set the background according to the window size
        const style = {
          backgroundImage: `url(${width <= 850 ? slide.background_mobile : slide.background})`,
        };

        return (
          <div className="slide" id={`slide${index}`} key={`slide${index}`} style={style}>
            <div className="content">
              <div className="title">
                <h1>{slide.title}</h1>
              </div>

              <div className="description">
                <ReactMarkdown children={slide.description} />

                <div style={button} className="buttons">
                  {slide.learn_more_button && (
                    <Link to={`${config.base_url}full-fibre`}>
                      <Button className="primary">{formatMessage(messages.learn_more)}</Button>
                    </Link>
                  )}
                  {slide.check_my_availability_button && (
                    <Link to={`${config.base_url}coverage`}>
                      <Button className="gradient">
                        {formatMessage(messages.navigation_get_connected)}
                      </Button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
